.contact-container {
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 3;
}

.contact-links {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.link-container {
  align-items: center;
  /* border: solid 2px #000; */
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: flex-start;
  margin-top: 10px;
  padding-top: 20px;
  width: 270px;
}

.resume-link,
.github-link,
.linkedin-link,
.email-link {
  font-size: 2em;
  margin-bottom: 50px;
  text-decoration: none;
}

.contact-icon {
  height: 70px;
  width: 70px;
}