.nav-bar-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  height: 100%;
  justify-content: flex-start;
}

.blur {
  -webkit-filter: opacity(0.25);
  filter:opacity(0.25);
  -webkit-filter: blur(5px);
  filter:blur(5px);

}

.profile-pic {
  border: solid 2px #000;
  border-radius: 20px;
  height: 200px;
  margin-bottom: 40px;
  margin-top: 40px;
}

.nav-links {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
}

.nav-item {
  font-size: 1.5em;
  padding-bottom: 32px;
  padding-top: 32px;
  text-decoration: none;

  background-image: linear-gradient(to left,
    transparent,
    transparent 50%,
  #333 50%,
  #333);
  background-position: 100% 0;
  background-size: 200% 100%;
  transition: all .25s ease-in;
}

.nav-item:hover {
  cursor: pointer;
}

.inactive-item {
  border-bottom: solid 2px #fff;
  border-top: solid 2px #fff;
}

.active-item {
  border-bottom: solid 2px #000;
  border-top: solid 2px #000;

  background-position: 0 0;
  color:#fff;
}