.header-container {
  align-items: center;
  border-bottom: solid 1px #000;
  display: flex;
  /* flex-direction: row; */
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 2;
  justify-content: center;
}

.name-title {
  font-size: 2em;
  font-weight: 700;
  margin: 0;
}

.last-name-title {
  margin-left: 35px;
}

.job-title {
  border-bottom: solid 1px #000;
  border-top: solid 1px #000;
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 0;
  margin-left: 60px;
  margin-top: 5px;
}