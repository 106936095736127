.skills-container {
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 3;
}

.skills-box {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  height: 600px;
  justify-content: center;
  overflow: auto;
}

.front-end-skills,
.back-end-skills,
.testing-skills,
.workflow-skills,
.currently-learning-skills {
  align-items: center;
  border: solid 2px #000;
  display: flex;
  flex-direction: column;
  height: 550px;
  justify-content: flex-start;
  margin: 20px 20px 20px 20px;
  width: 280px;
}

.angular-logo {
  height: 60px;
  margin-bottom: 10px;
  width: 154px;
}

.chaimocha-logos {
  height: 76px;
  margin-bottom: 20px;
  width: 200px;
}

.cucumber-logo {
  height: 64px;
  width: 158px;
}

.ember-logo {
  height: 72px;
  margin-bottom: 20px;
  width: 154px;
}

.enzymejest-logos {
  height: 52px;
  margin-bottom: 20px;
  width: 140px;
}

.express-logo {
  height: 32px;
  margin-bottom: 20px;
  width: 144px;
}

.git-logo {
  height: 58px;
  margin-bottom: 40px;
  width: 150px;
}

.github-logo {
  height: 38px;
  margin-bottom: 20px;
  width: 154px;
}

.heroku-logo {
  height: 78px;
  margin-bottom: 20px;
  width: 162px;
}

.htmlcssjs-logos {
  height: 66px;
  margin-bottom: 30px;
  width: 168px;
}

.jira-logo {
  height: 134px;
  width: 188px;
}

.jquery-logo {
  height: 42px;
  width: 140px;
}

.knex-logo {
  height: 54px;
  margin-bottom: 20px;
  width: 146px;
}

.mssql-logo {
  height: 98px;
  width: 130px;
}

.node-logo {
  height: 46px;
  margin-bottom: 30px;
  width: 154px;
}

.postgresql-logo {
  height: 116px;
  margin-bottom: 20px;
  width: 148px;
}

.react-logo {
  height: 68px;
  margin-bottom: 20px;
  width: 154px;
}

.react-router-logo {
  height: 68px;
  margin-bottom: 28px;
  width: 154px;
}

.redux-logo {
  height: 60px;
  margin-bottom: 30px;
  width: 150px;
}

.travisci-logo {
  height: 44px;
  width: 134px;
}

.vue-logo {
  height: 74px;
  margin-bottom: 20px;
  width: 154px;
}

.waffleio-logo {
  height: 66px;
  width: 154px;
}