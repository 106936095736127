body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  display: grid;
  font-family: 'Lato', sans-serif;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 100px 1fr;
  text-align: center;
}

.header-container {
  -webkit-align-items: center;
          align-items: center;
  border-bottom: solid 1px #000;
  display: -webkit-flex;
  display: flex;
  /* flex-direction: row; */
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 2;
  -webkit-justify-content: center;
          justify-content: center;
}

.name-title {
  font-size: 2em;
  font-weight: 700;
  margin: 0;
}

.last-name-title {
  margin-left: 35px;
}

.job-title {
  border-bottom: solid 1px #000;
  border-top: solid 1px #000;
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 0;
  margin-left: 60px;
  margin-top: 5px;
}
.nav-bar-container {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  height: 100%;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.blur {
  -webkit-filter: opacity(0.25);
  filter:opacity(0.25);
  -webkit-filter: blur(5px);
  filter:blur(5px);

}

.profile-pic {
  border: solid 2px #000;
  border-radius: 20px;
  height: 200px;
  margin-bottom: 40px;
  margin-top: 40px;
}

.nav-links {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0;
  width: 100%;
}

.nav-item {
  font-size: 1.5em;
  padding-bottom: 32px;
  padding-top: 32px;
  text-decoration: none;

  background-image: linear-gradient(to left,
    transparent,
    transparent 50%,
  #333 50%,
  #333);
  background-position: 100% 0;
  background-size: 200% 100%;
  transition: all .25s ease-in;
}

.nav-item:hover {
  cursor: pointer;
}

.inactive-item {
  border-bottom: solid 2px #fff;
  border-top: solid 2px #fff;
}

.active-item {
  border-bottom: solid 2px #000;
  border-top: solid 2px #000;

  background-position: 0 0;
  color:#fff;
}
.lp-container {
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 3;
  height: 600px;
  overflow: auto;
}

.landing-logo-container,
.testing-logo-container {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  margin: auto;
  width: 100%;
}

.landing-logos-react {
  height: 102px;
  margin-left: 40px;
  margin-right: 40px;
  width: 240px;
}

.landing-logos-react-router {
  height: 100px;
  margin-bottom: 5px;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 5px;
  width: 244px;
}

.landing-logos-redux {
  height: 90px;
  margin-bottom: 10px;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 10px;
  width: 250px;
}

.landing-logos-css {
  height: 158px;
  margin-bottom: 30px;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 30px;
  width: 270px;
}

.testing-logos {
  height: 90px;
  margin-bottom: 100px;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 10px;
  width: 270px;
}
.bio-container {
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 3;
}
.skills-container {
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 3;
}

.skills-box {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  height: 600px;
  -webkit-justify-content: center;
          justify-content: center;
  overflow: auto;
}

.front-end-skills,
.back-end-skills,
.testing-skills,
.workflow-skills,
.currently-learning-skills {
  -webkit-align-items: center;
          align-items: center;
  border: solid 2px #000;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 550px;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin: 20px 20px 20px 20px;
  width: 280px;
}

.angular-logo {
  height: 60px;
  margin-bottom: 10px;
  width: 154px;
}

.chaimocha-logos {
  height: 76px;
  margin-bottom: 20px;
  width: 200px;
}

.cucumber-logo {
  height: 64px;
  width: 158px;
}

.ember-logo {
  height: 72px;
  margin-bottom: 20px;
  width: 154px;
}

.enzymejest-logos {
  height: 52px;
  margin-bottom: 20px;
  width: 140px;
}

.express-logo {
  height: 32px;
  margin-bottom: 20px;
  width: 144px;
}

.git-logo {
  height: 58px;
  margin-bottom: 40px;
  width: 150px;
}

.github-logo {
  height: 38px;
  margin-bottom: 20px;
  width: 154px;
}

.heroku-logo {
  height: 78px;
  margin-bottom: 20px;
  width: 162px;
}

.htmlcssjs-logos {
  height: 66px;
  margin-bottom: 30px;
  width: 168px;
}

.jira-logo {
  height: 134px;
  width: 188px;
}

.jquery-logo {
  height: 42px;
  width: 140px;
}

.knex-logo {
  height: 54px;
  margin-bottom: 20px;
  width: 146px;
}

.mssql-logo {
  height: 98px;
  width: 130px;
}

.node-logo {
  height: 46px;
  margin-bottom: 30px;
  width: 154px;
}

.postgresql-logo {
  height: 116px;
  margin-bottom: 20px;
  width: 148px;
}

.react-logo {
  height: 68px;
  margin-bottom: 20px;
  width: 154px;
}

.react-router-logo {
  height: 68px;
  margin-bottom: 28px;
  width: 154px;
}

.redux-logo {
  height: 60px;
  margin-bottom: 30px;
  width: 150px;
}

.travisci-logo {
  height: 44px;
  width: 134px;
}

.vue-logo {
  height: 74px;
  margin-bottom: 20px;
  width: 154px;
}

.waffleio-logo {
  height: 66px;
  width: 154px;
}
.projects-container {
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 3;
}

.project-links-box {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  height: 600px;
  -webkit-justify-content: center;
          justify-content: center;
  overflow: auto;
}

.projects-titles-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 20px;
}

.projects-title {
  margin: 0;
}

.mentormatch-container,
.ebmdb-container,
.bandr-container,
.swapibox-container,
.gametime-container {
  border: solid 2px #000;
  margin-bottom: 30px;
}

.mentormatch-container:hover,
.ebmdb-container:hover,
.bandr-container:hover,
.swapibox-container:hover,
.gametime-container:hover {
  cursor: pointer;
} 

.mm-screenshot,
.bar-screenshot,
.ebmdb-screenshot,
.swapi-screenshot,
.gametime-screenshot {
  border-top: solid 2px #000;
  height: 400px;
  width: 750px;
}
.contact-container {
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 3;
}

.contact-links {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
}

.link-container {
  -webkit-align-items: center;
          align-items: center;
  /* border: solid 2px #000; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 200px;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-top: 10px;
  padding-top: 20px;
  width: 270px;
}

.resume-link,
.github-link,
.linkedin-link,
.email-link {
  font-size: 2em;
  margin-bottom: 50px;
  text-decoration: none;
}

.contact-icon {
  height: 70px;
  width: 70px;
}
.project-modal-outer {
  background-color: rgba(211,211,211,0.75);
  bottom: 0;
  position: absolute;
  height: 100%;
  left: 0;
  width: 100%;

  -webkit-animation: fadeIn .2s;
  animation: fadeIn .2s;
}

.project-modal-container {
  -webkit-animation: 0.50s ease-out 0s 1 slideInFromTop;
          animation: 0.50s ease-out 0s 1 slideInFromTop;
  background-color: #fff;
  border: solid 2px #000;
  box-shadow: 0px 0px 20px 20px rgba(0,0,0,0.15);
  position: fixed;
  bottom: 10%;
  left: 25%;
  right: 30%;
  top: 10%;
  width: 800px;
}

.modal-heading {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.title-container {
  width: 420px;
}

.exit-btn {
  background-color: rgb(255, 0, 0);
  border: solid 2px #000;
  color: #fff;
  font-size: 2em;
  font-weight: 700;
  height: 50px;
  margin-right: 18%;
  padding: 2px;
  text-align: center;
  width: 50px;
}

.exit-btn:hover {
  cursor: pointer;
}

.exit-btn:active {
  background-color: #fff;
  color: rgb(255, 0, 0);
}

.exit-btn:focus {
  outline: none;
}

.modal-screenshot {
  height: 300px;
  width: 550px;
}

.project-details-container {
  background-color: #fff;
  border: solid 2px #000;
  position: relative;
  margin: auto;
  top: -2%;
  width: 80%;
}

.modal-detail-header {
  font-weight: 700;
}

@-webkit-keyframes fadeIn {
  0% {
    background: rgba(211,211,211,0)
  }
  
  50% {
    background: rgba(211,211,211,0.375);
  }
  
  100% {
    background: rgba(211,211,211,0.75);
  }
}

@keyframes fadeIn {
  0% {
    background: rgba(211,211,211,0)
  }
  
  50% {
    background: rgba(211,211,211,0.375);
  }
  
  100% {
    background: rgba(211,211,211,0.75);
  }
}

@-webkit-keyframes slideInFromTop {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slideInFromTop {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
