.project-modal-outer {
  background-color: rgba(211,211,211,0.75);
  bottom: 0;
  position: absolute;
  height: 100%;
  left: 0;
  width: 100%;

  -webkit-animation: fadeIn .2s;
  animation: fadeIn .2s;
}

.project-modal-container {
  animation: 0.50s ease-out 0s 1 slideInFromTop;
  background-color: #fff;
  border: solid 2px #000;
  box-shadow: 0px 0px 20px 20px rgba(0,0,0,0.15);
  position: fixed;
  bottom: 10%;
  left: 25%;
  right: 30%;
  top: 10%;
  width: 800px;
}

.modal-heading {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.title-container {
  width: 420px;
}

.exit-btn {
  background-color: rgb(255, 0, 0);
  border: solid 2px #000;
  color: #fff;
  font-size: 2em;
  font-weight: 700;
  height: 50px;
  margin-right: 18%;
  padding: 2px;
  text-align: center;
  width: 50px;
}

.exit-btn:hover {
  cursor: pointer;
}

.exit-btn:active {
  background-color: #fff;
  color: rgb(255, 0, 0);
}

.exit-btn:focus {
  outline: none;
}

.modal-screenshot {
  height: 300px;
  width: 550px;
}

.project-details-container {
  background-color: #fff;
  border: solid 2px #000;
  position: relative;
  margin: auto;
  top: -2%;
  width: 80%;
}

.modal-detail-header {
  font-weight: 700;
}

@keyframes fadeIn {
  0% {
    background: rgba(211,211,211,0)
  }
  
  50% {
    background: rgba(211,211,211,0.375);
  }
  
  100% {
    background: rgba(211,211,211,0.75);
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}